export const fetchTopTracks = async (token, howLong) => {
    // Create a key based on the current date and the howLong variable
    const today = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
    const storageKey = `topTracks_${today}_${howLong}`;

    // Try to retrieve data from localStorage
    const cachedData = localStorage.getItem(storageKey);

    if (cachedData) {
        // If data is present in localStorage, parse and return it
        return JSON.parse(cachedData);
    }

    // If data is not present in localStorage, make the API request
    const response = await fetch(`https://api.spotify.com/v1/me/top/tracks?time_range=${howLong}&limit=50`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    const data = await response.json();

    // Store the result in localStorage
    localStorage.setItem(storageKey, JSON.stringify(data));

    return data;
};


export const fetchMusics = async (token, tracks) => {
    const ids = tracks.map(track => track.id).join(',');
    const response = await fetch(`https://api.spotify.com/v1/audio-features/?ids=${ids}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return await response.json();
};