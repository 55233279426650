import React, { useEffect, useState } from 'react'
import "./Index.css"

const colors = ["#294445", "#608D71", "#A6B287", "#E69462", "#C35C29"]
const coloors = ["#48191B", "#81694E", "#294445", "#608D71", "#A6B287", "#E69462", "#C35C29", "#4C9395", "#34AFAD", "#2E3E4D", "#0D5662"]

const popu = {

    "0": "???",
    "10": "Unknown tracks",
    "20": "Small artists",
    "30": "Very low",
    "40": "A bit popular",
    "50": "Quite popular",
    "60": "Popular",
    "70": "Very popular",
    "80": "Highly popular",
    "90": "Mainstream",
}

export const Index = ({ token, changeIndex }) => {

    const [howLong, setHowLong] = useState('long_term');
    const [tracks, setTracks] = useState([]);
    const [infos, setInfos] = useState(null);
    const [parAnnees, setParAnnees] = useState(false);
    const [infos2, setInfos2] = useState(null);


    useEffect(() => {

        let fetching = async () => {

            const res = await fetch('https://api.spotify.com/v1/me/top/tracks?time_range=' + howLong + '&limit=50', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                }),
            });

            const parse = await res.json();

            //Analyse

            //classement
            //popularite
            //decenies
            let decenies = new Map();
            let annees = new Map();
            let artists = {
                max: 1,
            };

            let anneeMoyenne = 0;
            let popuMoyenne = 0;

            if (!parse.items) return;

            for (const item of parse.items) {

                let del = item.album.release_date.split("-");
                if (del[1]) {
                    del[0] = parseInt(del[1]) > 6 ? parseInt(del[0]) + 1 : parseInt(del[0]);
                }
                const annee = parseInt(del[0]);
                anneeMoyenne += annee;
                if (decenies.has(Math.floor(annee / 10) * 10)) decenies.set(Math.floor(annee / 10) * 10, decenies.get(Math.floor(annee / 10) * 10) + 1)
                else decenies.set(Math.floor(annee / 10) * 10, 1)

                if (annees.has(annee)) annees.set(annee, annees.get(annee) + 1)
                else annees.set(annee, 1);

                popuMoyenne += item.popularity;

                //get the most present artist in item.artists

                

                for (const artist of item.artists) {
                    if (artists[artist.name]) {
                        artists[artist.name]++;
                        if (artists[artist.name] > artists.max) {
                            artists.max = artists[artist.name];
                            artists.maxArtist = artist.name;
                        }
                    } else {
                        artists[artist.name] = 1;
                    }
                }

            }

            popuMoyenne = Math.round(popuMoyenne / parse.items.length);
            anneeMoyenne = Math.round(anneeMoyenne / parse.items.length);

            setTracks(parse.items);
            setInfos({
                popuMoyenne,
                artisteFavo: artists.max > 2 ? { nom: artists.maxArtist, nb: artists.max } : null,
                annees: Array.from(new Map([...annees.entries()].sort()), ([annee, nombre]) => ({ annee, nombre })),
                decennies: Array.from(new Map([...decenies.entries()].sort()), ([annee, nombre]) => ({ annee, nombre })),
                anneeMoyenne,
            })
            fetchMusics(parse.items);
        }

        fetching();

    }, [token, howLong])

    const handleDisconnect = () => {
        document.cookie = "token=; expires= Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload(true);
    }

    const fetchMusics = async (tracks) => {

        let danceability = 0;
        let energy = 0;
        let tempo = 0;

        let ids = tracks.reduce(((prev, curr) => prev === "" ? curr.id : prev + "," + curr.id), "")

        const res = await fetch('https://api.spotify.com/v1/audio-features/?ids=' + ids, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
            }),
        });

        const parse = await res.json();
        if (!parse.audio_features) return;
        if(parse.audio_features[0] === null) {
            setTimeout(fetchMusics,2000);
            return;
        }
        for (let item of parse.audio_features) {
            if (item && item.tempo) {
                tempo += item?.tempo;
                energy += item?.energy;
                danceability += item?.danceability;
            }
        }

        danceability = danceability / tracks.length;
        energy = energy / tracks.length;
        tempo = tempo / tracks.length;

        setInfos2({
            danceability,
            energy,
            tempo
        })

    }

    const changePrecision = () => {
        setParAnnees(!parAnnees);
    }

    return (
        <div className="track_display">

            <div className="menu" style={{ width: 120 }}>


                <div className="menu_choice" style={{ opacity: 0.5 }}>
                    <img src="http://www.mcicon.com/wp-content/uploads/2021/01/Music_Music_1-copy-9.jpg" />
                    <p> Go to songs </p>
                </div>

                <div className="menu_choice" onClick={changeIndex}>
                    <img src="https://icon-library.com/images/musician-icon/musician-icon-19.jpg" />
                    <p> Go to artists </p>
                </div>

                <div className="menu_choice" onClick={handleDisconnect}>
                    <img src="https://static.thenounproject.com/png/683381-200.png" />
                    <p> Disconnect </p>
                </div>

            </div>

            <div>
                <div className="track_time">
                    <p onClick={() => setHowLong("long_term")} className={howLong === "short_term" ? "inactive" : ""}> Of all time </p>
                    <p onClick={() => setHowLong("short_term")} className={howLong === "short_term" ? "" : "inactive"}> Currently </p>
                </div>
                <div className="track_border">
                    <div className="track_list">

                        {tracks.map((e, i) => (

                            <div className="track" key={e.id}>

                                <img className="track_image" src={e.album.images[1].url} />
                                <div className="track_infos">
                                    <div className="track_noms">
                                        <p> {e.name.indexOf("(") !== -1 ? e.name.substring(0, e.name.indexOf("(")) : e.name} </p>
                                        <p className="track_artists"> {e.artists.reduce(((prev, curr) => prev === "" ? curr.name : prev + ", " + curr.name), "")} </p>
                                    </div>
                                    <div className="track_popu">
                                        <p> {i + 1} </p>
                                    </div>
                                </div>

                            </div>

                        ))}
                    </div>
                </div>
            </div>
            <div style={{ minHeight: "80vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>


                {infos &&
                    <>
                        <p className="track_anneeM"> Average year : <strong> {infos.anneeMoyenne} </strong> </p>
                        <p className="track_morePrecisions" onClick={changePrecision}> See {parAnnees ? "less" : "more"} precisions... </p>
                        {!parAnnees ?

                            <div className="track_decennies">

                                {infos.decennies.map((e, i) => {

                                    return (
                                        <div key={e.annee + "" + e.howLong} style={{ flex: 1, maxWidth: "50%", height: e.nombre * 3 }}>
                                            <div style={{ background: colors[i], position: "relative", overflow: "hidden", width: "100%", height: "100%" }}>
                                                <div className="track_anim" >  </div>
                                            </div>
                                            <p style={{ textAlign: `center`, fontSize: 23, marginTop: 5 }}> {e.annee} </p>
                                        </div>
                                    );

                                })}

                            </div>

                            :

                            <div className="track_decennies">

                                {infos.annees.map((e, i) => {

                                    return (
                                        <div key={e.annee + "" + e.howLong} style={{ flex: 1, maxWidth: "50%", minWidth: 40, height: e.nombre * 3 }}>
                                            <div style={{ background: `hsl(100,100%,${i * (100 / infos.annees.length)}%)`, position: "relative", overflow: "hidden", width: "100%", height: "100%" }}>
                                                <div className="track_anim" >  </div>
                                            </div>
                                            <p style={{ textAlign: `center`, fontSize: 15, marginTop: 5 }}> {e.annee} </p>
                                        </div>
                                    );

                                })}
                            </div>

                        }


                        <p className="track_anneeM"> Average popularity : <strong> {popu[Math.floor(infos.popuMoyenne / 10) * 10]} </strong> <span style={{ fontSize: 20 }}> ({infos.popuMoyenne}/100) </span> </p>

                        {infos.artisteFavo && <p className="track_anneeM" style={{ maxWidth: 380, marginTop: 40 }}> This artist is close to your heart: <strong> {infos.artisteFavo.nom} </strong> with <strong> {infos.artisteFavo.nb} </strong> songs in your Top 50 </p>}


                    </>}

                <p className="track_anneeM" style={{ maxWidth: 380, marginTop: 40 }}> Autres informations : </p>

                {infos2 &&
                    <>
                        <div style={{ borderRadius: 3, width: 320, marginTop: 10, background: `#C2C1B2`, height: 30, display: "flex", overflow: "hidden", alignItems: `center`, position: "relative" }}>
                            <div className="track_statAnim"> </div>
                            <div style={{ width: infos2.energy * 320, background: coloors[5], height: 30, display: "flex", alignItems: `center` }}>
                                <p className="track_statName"> Energy ({Math.floor(infos2.energy * 100) / 100}/1) </p>
                            </div>
                        </div>
                        <div style={{ borderRadius: 3, width: 320, marginTop: 10, background: `#C2C1B2`, height: 30, display: "flex", overflow: "hidden", alignItems: `center`, position: "relative" }}>
                            <div className="track_statAnim"> </div>
                            <div style={{ width: infos2.danceability * 320, background: coloors[6], height: 30, display: "flex", alignItems: `center` }}>
                                <p className="track_statName"> Danceability ({Math.floor(infos2.danceability * 100) / 100}/1) </p>
                            </div>
                        </div>
                        <div style={{ borderRadius: 3, width: 320, marginTop: 10, background: `#C2C1B2`, height: 30, display: "flex", overflow: "hidden", alignItems: `center`, position: "relative" }}>
                            <div className="track_statAnim"> </div>
                            <div style={{ width: ((infos2.tempo - 40) * 320) / 170, background: coloors[3], height: 30, display: "flex", alignItems: `center` }}>
                                <p className="track_statName"> Tempo ({Math.round(infos2.tempo)}) </p>
                            </div>
                        </div>
                    </>
                }


            </div>
        </div>
    )
}
