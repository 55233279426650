import React, { Component } from "react";
import "./Conn.css"

const authEndpoint = "https://accounts.spotify.com/authorize";

const clientId = "eb934ed9439a40008cd7b34e49b662f2";
const redirectUri = "https://spotify.eliottgandiolle.fr/";
const scopes = [
  "user-read-currently-playing",
  "user-read-playback-state",
  "user-read-recently-played",
  "user-top-read"
];

export default class ConnexionPage extends Component {

  redirectUri = () => {
    window.location.href = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`
  }

  render() {
    return (
      <div style={{ backgroundImage: "url(https://images.pexels.com/photos/1601775/pexels-photo-1601775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)", backgroundSize: "cover", height: "100vh", width: "100%", position: "relative" }}> 
        <div style={{ height: "100%", width: "100%", position: "absolute", backgroundColor:"rgba(0,0,0,0.7)", display: "flex", flexDirection: "column", top: 0, left: 0, alignItems: `center`, justifyContent: `center`}}>
            <div style={{ padding: 10 }}>
            <h1 style={{ color: "lightgray", maxWidth: 500 }}> Let's analyze your spotify account, to see if you have good tastes... </h1>
            <button className="glow-on-hover" onClick={this.redirectUri}>
            Connexion
            </button>
            </div>
        </div>
        
      </div>
    );
  }
}
