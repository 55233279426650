import React from 'react';
import SongsYear from './SongsYear';

const SongsYears = ({ years, colors, total, isDecennies }) => {
    return (
        <div className="songs_decennies">
            {years.map((e, i) => {
                return (
                    <SongsYear
                        key={e.annee + "" + e.howLong}
                        bgColor={colors[i]}
                        isDecennies={isDecennies}
                        percentage={79 * e.nombre / total}
                        annee={e.annee}
                    />
                );
            })}
        </div>
    );
}

export default SongsYears;
