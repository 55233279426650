import React, { useEffect, useState } from 'react';
import { fetchTopTracks, fetchMusics } from './logique/spotifyService';
import { processTrackData, processMusicData } from './logique/trackUtils';
import "./index.css"
import SongsList from './SongsList';
import SongsYears from './SongsYears';
import PopularityComponent from './PopularityComponent';
import SongsYear from './SongsYear';
import { MusicDurationComponent } from './MusicDuration';

const colors = [
    '#FF00FF', // Neon Pink
    '#00FFFF', // Neon Cyan
    '#FFD700', // Neon Yellow
    '#00FF00', // Neon Green
    '#FF4500', // Neon Orange
    '#1E90FF', // Neon Blue
];

const colors2 = [
    '#00FF00',
    '#1FFF00',
    '#3EFF00',
    '#5DFF00',
    '#7CFF00',
    '#9BFF00',
    '#BAFF00',
    '#D9FF00',
    '#F8FF00',
    '#FFEA00',
    '#FFDB00',
    '#FFCC00',
    '#FFBD00',
    '#FFAE00',
    '#FF9F00',
    '#FF9000',
    '#FF8100',
    '#FF7200',
    '#FF6300',
    '#FF5400',
    '#FF4500', // Transition to Orange
    '#FF5000',
    '#FF5B00',
    '#FF6600',
    '#FF7100',
    '#FF7C00',
    '#FF8700',
    '#FF9200',
    '#FF9D00',
    '#FFA800',
    '#FFB300', // Approaching Yellow
    '#FFBE00',
    '#FFC900',
    '#FFD400',
    '#FFDF00',
    '#FFEA00',
    '#FFF500',
    '#FFFF00'  // Neon Yellow
];


export const Tracks = ({ token }) => {
    const [tracks, setTracks] = useState([]);
    const [infos, setInfos] = useState(null);
    const [howLong, setHowLong] = useState('long_term');
    const [energyLevels, setEnergyLevels] = useState(null);
    const [isDecades, setIsDecades] = useState(true);

    useEffect(() => {
        const fetchDataAndProcess = async () => {
            const dataLongTerm = await fetchTopTracks(token, 'long_term');
            const dataShortTerm = await fetchTopTracks(token, 'short_term');
            if (!dataLongTerm.items || !dataShortTerm.items) return;

            const { tracks: tracksLT, infos: infosLT } = processTrackData(dataLongTerm);
            const { tracks: tracksST, infos: infosST } = processTrackData(dataShortTerm);

            let sameMusics = new Map();
            let duplicatedTracks = [];

            [...tracksLT, ...tracksST].forEach(track => {
                if (sameMusics.has(track.id)) {
                    if (sameMusics.get(track.id).count === 1) { // Only add the second occurrence
                        duplicatedTracks.push(track);
                    }
                    sameMusics.get(track.id).count++;
                } else {
                    sameMusics.set(track.id, { track, count: 1 });
                }
            });


            setTracks({
                long_term: tracksLT,
                short_term: tracksST
            });
            setInfos({
                long_term: { ...infosLT, sameMusics: duplicatedTracks },
                short_term: { ...infosST, sameMusics: duplicatedTracks }
            });

            if (howLong === 'long_term') {
                const musicData = await fetchMusics(token, tracksLT);
                const processedMusicData = processMusicData(musicData, tracksLT);
                setEnergyLevels(processedMusicData);
            } else {
                const musicData = await fetchMusics(token, tracksST);
                const processedMusicData = processMusicData(musicData, tracksST);
                setEnergyLevels(processedMusicData);
            }
        };

        fetchDataAndProcess();
    }, [token, howLong]);

    const handleDisconnect = () => {
        document.cookie = "token=; expires= Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload(true);
    }


    if (!infos || !infos[howLong] || !infos[howLong].mostPopular) return null;

    return (
        <div className="songs_content">
            <p className="section_title" onClick={handleDisconnect} style={{ marginTop: 20 }}>Most listened tracks</p>
            <div className="time_ranges">
                <div
                    className={`time_range ${howLong === 'long_term' ? 'active' : 'inactive'}`}
                    onClick={() => setHowLong('long_term')}
                >
                    All time
                </div>
                <div
                    className={`time_range ${howLong === 'short_term' ? 'active' : 'inactive'}`}
                    onClick={() => setHowLong('short_term')}
                >
                    Short term (4 weeks)
                </div>
            </div>
            <SongsList tracks={tracks[howLong]} />
            <PopularityComponent infos={infos[howLong]} />
            {infos[howLong].artisteFavo && <p className="section_title" style={{ fontWeight: 500 }}> <strong className="separatorSpan"> {infos[howLong].artisteFavo.nom} </strong> is close to your heart with <strong className="separatorSpan"> {infos[howLong].artisteFavo.nb} songs</strong>  in your Top 50 ❤️</p>}
            {energyLevels &&
                <>
                    <p className="section_title"> By characteristics</p>
                    <SongsYear
                        bgColor={colors[0]}
                        percentage={Math.floor(energyLevels.energy * 100) * 0.7}
                        text={<>Energy<strong> {`${Math.round(energyLevels.energy * 10) / 10}/1`}</strong></>} // Replace with your desired text
                    />
                    <SongsYear
                        bgColor={colors[1]}
                        percentage={Math.floor(energyLevels.danceability * 100) * 0.7}
                        text={<>Danceability<strong> {`${Math.round(energyLevels.danceability * 10) / 10}/1`}</strong></>} // Replace with your desired text
                    />
                    <SongsYear
                        bgColor={colors[2]}
                        percentage={0.7 * (energyLevels.tempo > 180 ? 100 : energyLevels.tempo < 60 ? 10 : 10 + ((energyLevels.tempo - 60) * 90 / 120))}
                        text={<>BPM<strong> {`${Math.floor(energyLevels.tempo * 100) / 100}`}</strong></>} // Replace with your desired text
                    />
                    <MusicDurationComponent musicDuration={energyLevels.musicDuration} />
                </>
            }
            {infos[howLong].sameMusics.length > 0 && <><p className="section_title">You are loyal to </p>
                <SongsList tracks={infos[howLong].sameMusics} isOneLine /></>}
            <p className="song_duration">These are in your <strong className="separatorSpan">Top 50</strong> both <strong>all time</strong> and <strong>recently</strong></p>
            <p className="section_title">By <span onClick={() => setIsDecades(true)} style={{ opacity: isDecades ? 1 : 0.6 }}>Decades</span> <span className="separatorSpan">/</span> <span onClick={() => setIsDecades(false)} style={{ opacity: !isDecades ? 1 : 0.6 }}>Years</span></p>
            {isDecades ? <SongsYears isDecennies years={infos[howLong].decennies} colors={colors} total={tracks[howLong].length} />
                : <SongsYears years={infos[howLong].annees} colors={colors2} total={tracks[howLong].length} />}
        </div>
    )
}