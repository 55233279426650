export const processTrackData = (data) => {
    let decades = new Map();
    let years = new Map();
    let artistsCount = { max: 1 };
    let averageYear = 0;
    let averagePopularity = 0;

    for (const item of data.items) {
        const [yearStr] = item.album.release_date.split("-");
        let year = parseInt(yearStr);

        averageYear += year;
        const decade = Math.floor(year / 10) * 10;
        decades.set(decade, (decades.get(decade) || 0) + 1);
        years.set(year, (years.get(year) || 0) + 1);

        averagePopularity += item.popularity;

        for (const artist of item.artists) {
            artistsCount[artist.name] = (artistsCount[artist.name] || 0) + 1;
            if (artistsCount[artist.name] > artistsCount.max) {
                artistsCount.max = artistsCount[artist.name];
                artistsCount.maxArtist = artist.name;
            }
        }
    }

    let mostPopularSong = data.items[0];
    let leastPopularSong = data.items[0];

    for (const item of data.items) {
        if (item.popularity > mostPopularSong.popularity) {
            mostPopularSong = item;
        }
        if (item.popularity < leastPopularSong.popularity) {
            leastPopularSong = item;
        }
    }

    averagePopularity = Math.round(averagePopularity / data.items.length);
    averageYear = Math.round(averageYear / data.items.length);

    return {
        tracks: data.items,
        infos: {
            popuMoyenne: averagePopularity,
            artisteFavo: artistsCount.max > 2 ? { nom: artistsCount.maxArtist, nb: artistsCount.max } : null,
            annees: Array.from(years.entries()).sort().map(([year, count]) => ({ annee: year, nombre: count })),
            decennies: Array.from(decades.entries()).sort().map(([decade, count]) => ({ annee: decade, nombre: count })),
            anneeMoyenne: averageYear,
            mostPopular: mostPopularSong,
            leastPopular: leastPopularSong
        }
    };
};

export const processMusicData = (data, tracks) => {
    if (!data.audio_features || data.audio_features[0] === null) {
        return null; // or handle this case differently if needed
    }


    let danceability = 0;
    let energy = 0;
    let tempo = 0;
    let duration = 0;

    for (const item of data.audio_features) {
        if (item && item.tempo) {
            tempo += item.tempo;
            energy += item.energy;
            danceability += item.danceability;
            duration += item.duration_ms;
        }
    }

    const trackCount = tracks.length;
    return {
        danceability: danceability / trackCount,
        energy: energy / trackCount,
        tempo: tempo / trackCount,
        musicDuration: duration / trackCount
    };
};
