import React from 'react'

export const ArtistsList = ({ artists, isOneLine }) => {

    if(!artists) return <p style={{ color: "white" }}> Loading... </p>

    return (
        <div className="songs_list_wrapper">
            <div className="songs_list_overlay" />
            <div className="songs_list" style={{ height: !isOneLine ? 350 : 175 }}>
                {artists.map((e, i) => (
                    <div className="song" key={e.id}>
                        <img className="song_image" src={e.images[1].url} alt={`Album cover for ${e.name}`} />
                        <div className="song_infos">
                            <div className="song_noms">
                                <p className="song_title">
                                    {e.name.indexOf("(") !== -1 ? e.name.substring(0, e.name.indexOf("(")) : e.name}
                                </p>
                                <p className="song_artists">
                                    Popularity {e.popularity}
                                </p>
                            </div>
                            {!isOneLine && <div className="song_popu">
                                <p> {i + 1} </p>
                            </div>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
