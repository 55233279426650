import React from 'react';

const musicDurations = {
    10000: "super short",
    20000: "short",
    60000: "one-minute",
    180000: "three-minute",
    300000: "standard-length",
    600000: "epic",
    1200000: "never-ending"
    // Add more as needed
};

export function MusicDurationComponent({ musicDuration }) {
    let description;
    if (musicDuration < 10000) {
        description = musicDurations[10000];
    } else if (musicDuration < 20000) {
        description = musicDurations[20000];
    } else if (musicDuration < 60000) {
        description = musicDurations[60000];
    } else if (musicDuration < 180000) {
        description = musicDurations[180000];
    } else if (musicDuration < 300000) {
        description = musicDurations[300000];
    } else if (musicDuration < 600000) {
        description = musicDurations[600000];
    } else {
        description = musicDurations[1200000];
    }

    // Convert musicDuration to MM:ss format
    const totalSeconds = Math.floor(musicDuration / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedTime = `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`;

    return <p className="song_duration">You listen to <strong>{description}</strong> musics with an average time of <strong>{formattedTime}</strong>!</p>;
}
