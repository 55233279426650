import React, { useEffect, useState } from 'react'
import { ArtistsList } from './ArtistsList';
import PopularityComponent from '../Tracks/PopularityComponent';
import SongsYear from '../Tracks/SongsYear';

const colors = [
    '#FF00FF', // Neon Pink
    '#00FFFF', // Neon Cyan
    '#FFD700', // Neon Yellow
    '#00FF00', // Neon Green
    '#FF4500', // Neon Orange
    '#1E90FF', // Neon Blue
];

export const Artists = ({ token }) => {

    const [howLong, setHowLong] = useState('long_term');

    const handleDisconnect = () => {
        document.cookie = "token=; expires= Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload(true);
    }

    const [data, setData] = useState({
        long_term: { artists: [], infos: null },
        short_term: { artists: [], infos: null }
    });

    useEffect(() => {
        const fetchData = async (timeRange) => {
            const res = await fetch(`https://api.spotify.com/v1/me/top/artists?time_range=${timeRange}&limit=50`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const parsedData = await res.json();
            if (!parsedData.items) return;

            const genres = {};
            let totalPopularity = 0;

            for (const { genres: itemGenres, popularity } of parsedData.items) {
                for (const genre of itemGenres) {
                    genres[genre] = (genres[genre] || 0) + 1;
                }
                totalPopularity += popularity;
            }

            const averagePopularity = Math.round(totalPopularity / parsedData.items.length);
            const sortedGenres = Object.entries(genres).sort((b, a) => a[1] - b[1]);

            let mostPopularArtist = parsedData.items[0];
            let leastPopularArtist = parsedData.items[0];

            for (const item of parsedData.items) {
                if (item.popularity > mostPopularArtist.popularity) {
                    mostPopularArtist = item;
                }
                if (item.popularity < leastPopularArtist.popularity) {
                    leastPopularArtist = item;
                }
            }

            return {
                artists: parsedData.items,
                infos: {
                    popuMoyenne: averagePopularity,
                    genres: sortedGenres,
                    mostPopular: mostPopularArtist,
                    leastPopular: leastPopularArtist
                }
            };
        };

        if (!data.long_term.artists.length && !data.short_term.artists.length) {
            Promise.all([fetchData('long_term'), fetchData('short_term')]).then(([longTermData, shortTermData]) => {

                // Find artists that appear in both long_term and short_term
                let sameArtists = new Map();
                let duplicatedArtists = [];

                [...longTermData.artists, ...shortTermData.artists].forEach(artist => {
                    if (sameArtists.has(artist.id)) {
                        if (sameArtists.get(artist.id).count === 1) { // Only add the second occurrence
                            duplicatedArtists.push(artist);
                        }
                        sameArtists.get(artist.id).count++;
                    } else {
                        sameArtists.set(artist.id, { artist, count: 1 });
                    }
                });

                // Update the info state to include the duplicated artists
                setData(prevData => ({
                    ...prevData,
                    long_term: longTermData,
                    short_term: shortTermData,
                    infos: {
                        ...prevData.infos,
                        sameArtists: duplicatedArtists
                    }
                }));
            });
        }
    }, [token]);

    if (!data.long_term.infos || !data.short_term.infos) return <p style={{ color: "white" }}> Loading... </p>

    return (
        <div className="songs_content">
            <p className="section_title" onClick={handleDisconnect} style={{ marginTop: 20 }}>Most listened artists</p>
            <div className="time_ranges">
                <div
                    className={`time_range ${howLong === 'long_term' ? 'active' : 'inactive'}`}
                    onClick={() => setHowLong('long_term')}
                >
                    All time
                </div>
                <div
                    className={`time_range ${howLong === 'short_term' ? 'active' : 'inactive'}`}
                    onClick={() => setHowLong('short_term')}
                >
                    Short term (4 weeks)
                </div>
            </div>
            <ArtistsList artists={data[howLong].artists} />
            <PopularityComponent isArtist infos={data[howLong].infos} />
            {data.infos.sameArtists.length > 0 && <><p className="section_title">You are loyal to </p>
                <ArtistsList artists={data.infos.sameArtists} isOneLine /></>}
            <p className="song_duration">These are in your <strong className="separatorSpan">Top 50</strong> both <strong>all time</strong> and <strong>recently</strong></p>
            <p className="section_title" style={{ marginTop: 20 }}>Your top genres</p>
            {data[howLong].infos.genres.slice(0, 5).map((e, i) =>
                <SongsYear
                    key={e[0]}
                    bgColor={colors[i]}
                    percentage={50 * e[1] / data[howLong].infos.genres[0][1]}
                    text={e[0]}
                />
            )}

        </div>
    )
}
