import React from 'react';

function truncateString(str, n) {
    if (str.length > n) {
        return str.substring(0, n) + "...";
    } else {
        return str;
    }
}


const popu = {
    "0": "???",
    "10": "Unknown artists",
    "20": "Small artists",
    "30": "Very low",
    "40": "A bit popular",
    "50": "Quite popular",
    "60": "Popular",
    "70": "Very popular",
    "80": "Highly popular",
    "90": "Mainstream",
}


function PopularityComponent({ infos, isArtist }) {
    return (
        <>
            <p className="section_title">By popularity</p>
            <div className="popularity_wrapper">
                <div className="popularity_div_background" />
                <div className="popularity_div" style={{ height: '100%', width: infos.popuMoyenne + "%" }} />
            </div>
            <p className="popularity_text">Average: <strong> {popu[Math.floor(infos.popuMoyenne / 10) * 10]} </strong> <span> ({infos.popuMoyenne}/100) </span> </p>
            <div className="divider" />
            <div className="popularity_example_wrapper">
                <img src={isArtist ? infos.mostPopular.images[1].url : infos.mostPopular.album.images[1].url} alt="mostPopular" />
                <p> Your most popular {isArtist ? "artist" : "song"} is <strong> {truncateString(infos.mostPopular.name, 25)} </strong> with a score of <strong> {infos.mostPopular.popularity}/100</strong></p>
            </div>
            <div className="divider" />
            <div className="popularity_example_wrapper">
            <img src={isArtist ? infos.leastPopular.images[1].url : infos.leastPopular.album.images[1].url} alt="leastPopular" />
                <p> Your least popular {isArtist ? "artist" : "song"} is <strong> {truncateString(infos.leastPopular.name, 20)} </strong> with a score of <strong> {infos.leastPopular.popularity}/100</strong></p>
            </div>
        </>
    );
}

export default PopularityComponent;
