import React, { useEffect, useState } from 'react'
import "./Index.css"

const colors = ["#294445", "#608D71", "#A6B287", "#E69462", "#C35C29"]
const coloors = ["#48191B", "#81694E", "#294445", "#608D71", "#A6B287", "#E69462", "#C35C29", "#4C9395", "#34AFAD", "#2E3E4D", "#0D5662"]

const popu = {

    "0": "???",
    "10": "Unknown artists",
    "20": "Small artists",
    "30": "Very low",
    "40": "A bit popular",
    "50": "Quite popular",
    "60": "Popular",
    "70": "Very popular",
    "80": "Highly popular",
    "90": "Mainstream",
}

export const Index = ({ token, changeIndex }) => {

    const [howLong, setHowLong] = useState('long_term');
    const [artists, setArtists] = useState([]);
    const [infos, setInfos] = useState(null);

    useEffect(() => {

        let fetching = async () => {

            const res = await fetch('https://api.spotify.com/v1/me/top/artists?time_range=' + howLong + '&limit=50', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                }),
            });

            const parse = await res.json();
            //Analyse


            let genres = {};
            //classement
            //popularite
            //decenies

            let popuMoyenne = 0;

            // if (!parse.items) return;
            for (const item of parse.items) {

                for (let genre of item.genres) {
                    if (genres[genre]) genres[genre]++;
                    else genres[genre] = 1;
                }

                popuMoyenne += item.popularity;

            }

            popuMoyenne = Math.round(popuMoyenne / parse.items.length);
            let sortable = [];
            for (let genre in genres) {
                sortable.push([genre, genres[genre]]);
            }

            sortable.sort(function (a, b) {
                return a[1] - b[1];
            });
            setArtists(parse.items);
            setInfos({
                popuMoyenne,
                genres: sortable,
            })
        }

        fetching();

    }, [token, howLong])

    const handleDisconnect = () => {
        document.cookie = "token=; expires= Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload(true);
    }

    return (
        <div className="track_display">

            <div className="menu"  style={{ width: 120 }}>


                <div className="menu_choice" onClick={changeIndex}>
                    <img src="http://www.mcicon.com/wp-content/uploads/2021/01/Music_Music_1-copy-9.jpg" />
                    <p> Go to songs </p>
                </div>

                <div className="menu_choice" style={{ opacity: 0.5 }}>
                    <img src="https://icon-library.com/images/musician-icon/musician-icon-19.jpg" />
                    <p> Go to artists </p>
                </div>

                <div className="menu_choice" onClick={handleDisconnect}>
                    <img src="https://static.thenounproject.com/png/683381-200.png" />
                    <p> Disconnect </p>
                </div>

            </div>

            <div>
                <div className="track_time">
                    <p onClick={() => setHowLong("long_term")} className={howLong === "short_term" ? "inactive" : ""}> Of all time </p>
                    <p onClick={() => setHowLong("short_term")} className={howLong === "short_term" ? "" : "inactive"}> Currently </p>
                </div>
                <div className="track_border">
                    <div className="track_list">

                        {artists.map((e, i) => (

                            <div className="track" key={e.id}>

                                <img className="track_image" src={e.images[1].url} />
                                <div className="track_infos">
                                    <div className="track_noms">
                                        <p> {e.name.indexOf("(") !== -1 ? e.name.substring(0, e.name.indexOf("(")) : e.name} </p>
                                        <p className="track_artists"> {new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 }).format(e.followers.total)} Fame </p>
                                    </div>
                                    <div className="track_popu">
                                        <p> {i + 1} </p>
                                    </div>
                                </div>

                            </div>

                        ))}
                    </div>
                </div>
            </div>
            <div style={{ minHeight: "80vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>


                {infos &&
                    <>

                        <p className="track_anneeM"> Average popularity : <strong> {popu[Math.floor(infos.popuMoyenne / 10) * 10]} </strong> <span style={{ fontSize: 20 }}> ({infos.popuMoyenne}/100) </span> </p>

                        {infos.artisteFavo && <p className="track_anneeM" style={{ width: 380, marginTop: 40 }}> This artist is close to your heart: <strong> {infos.artisteFavo.nom} </strong> with <strong> {infos.artisteFavo.nb} </strong> songs in your Top 50 </p>}

                        <p className="track_anneeM" style={{ marginTop: 40 }}> Genre that you like the most :  </p>
                        {infos.genres.slice(0, 5).map((e, i) => <div style={{ marginTop: 5, alignItems: `center`, display: `flex`, justifyContent: `flex-start` }}> 
                        <p className="track_anneeM" style={{ marginRight: 15 ,paddingBottom: 2, borderRadius: 9, border: "1px solid black" ,alignItems: `center`, display: `flex`, justifyContent: `center`, fontWeight: `bold`, width: 40, height: 40 }}> {i+1} </p> <p className="track_anneeM" >  {e[0].charAt(0).toUpperCase() + e[0].slice(1)} </p> </div>)}

                    </>

                }


            </div>
        </div>
    )
}
