// SongDecennie.js
import React from 'react';

const SongsYear = ({ bgColor, percentage, annee, isDecennies, text }) => {
    return (
        <div className="songs_decennie">
            <div 
                className="song_bar" 
                style={{
                    color: bgColor,
                    background: bgColor,
                    width: `${percentage}%`
                }}
            />
            {text ? <p>{text}</p> : <p> {annee}{isDecennies ? "'s" : ""} </p>}
        </div>
    );
}

export default SongsYear;
