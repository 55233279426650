import React, { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider } from 'react-query';
import ConnectionPage from "./Connection/ConnectionPage";
import { Index as IndexM } from "./Analyze/IndexMusique";
import { Index, Index as IndexA } from "./Analyze/IndexArtistes";
import { Navigator } from "./MainPage/Navigator";
import { useWindowWidth } from "./useWindowWidth";

const hash = window.location.hash
  .substring(1)
  .split("&")
  .reduce((initial, item) => {
    if (item) {
      const parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
    }
    return initial;
  }, {});

window.location.hash = "";

const queryClient = new QueryClient();

const App = () => {
  const [songs, setSongs] = useState(true);
  const [token, setToken] = useState(
    undefined ||
    document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
  );

  const changeTo = () => {
    setSongs(!songs);
  };

  useEffect(() => {
    if (!token) {
      const _token = hash.access_token;
      if (_token) {
        const now = new Date();
        now.setTime(now.getTime() + 1 * 3600 * 1000);
        document.cookie = "token=" + _token + "; expires=" + now.toUTCString();
        setToken(_token);
      }
    }
  }, [token]);

  const windowWidth = useWindowWidth();

  return (
    <QueryClientProvider client={queryClient}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {!token ? 
        <ConnectionPage /> : 
        windowWidth < 768 ? 
        <Navigator token={token} /> : 
        songs ? <IndexM token={token} changeIndex={changeTo} /> : <IndexA token={token} changeIndex={changeTo} /> }
      </div>
    </QueryClientProvider>
  );
};

export default App;
