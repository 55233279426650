import React, { useState } from 'react'
import AlbumIcon from '@mui/icons-material/Album';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import "./Navigator.css"
import { Tracks } from './Tracks';
import { Artists } from './Artists/Artists';

export const Navigator = ({ token }) => {

  const [index, setIndex] = useState(0);

  return (
    <div className="content">
        {
            index === 0 ? <Tracks token={token} /> : <Artists token={token} />
        }
        <div className="bottomNavigator">
            <div className={`iconContainer ${index === 0 ? 'selectedOne' : ''}`} onClick={() => setIndex(0)}>
                <LibraryMusicIcon />
                <p>Tracks</p>
            </div>
            <div className="dividerr" />
            <div className={`iconContainer ${index === 1 ? 'selectedOne' : ''}`} onClick={() => setIndex(1)}>
                <AlbumIcon />
                <p>Artists</p>
            </div>
        </div>
    </div>
  )
}
